<template>
  <div class="col-12">
    <div class="card card--profile-tasks">
      <div class="card-body">
        <div class="row">
          <div class="col">
            <div class="clearfix">
              <h3 class="float-start">
                <i
                  data-feather="toggle-left"
                  class="w-icon-24"
                /> Tasks
              </h3>
              <span class="float-end">
                <div
                  class="btn-group"
                  role="group"
                  aria-label="Basic radio toggle button group"
                >
                  <input
                    id="btnradio1"
                    type="radio"
                    class="btn-check"
                    name="btnradio"
                    autocomplete="off"
                    checked
                    @click="filter = 0"
                  >
                  <label
                    class="btn btn-sm btn-outline-secondary"
                    for="btnradio1"
                  >All</label>

                  <input
                    id="btnradio2"
                    type="radio"
                    class="btn-check"
                    name="btnradio"
                    autocomplete="off"
                  >
                  <label
                    class="btn btn-sm btn-outline-secondary"
                    for="btnradio2"
                    @click="filter = 1"
                  >Pending</label>

                  <input
                    id="btnradio3"
                    type="radio"
                    class="btn-check"
                    name="btnradio"
                    autocomplete="off"
                  >
                  <label
                    class="btn btn-sm btn-outline-secondary"
                    for="btnradio3"
                    @click="filter = 2"
                  >Completed</label>
                </div>
              </span>
            </div>

            <hr>
            <template v-if="loading">
              <div
                class="loading custom-loading"
              >
                <div class="effect-1 effects" />
                <div class="effect-2 effects" />
                <div class="effect-3 effects" />
              </div>
            </template>
            <div
              v-else
              class="app-content  todo-application"
            >
              <div>
                <div class="content-right w-100">
                  <div class="content-wrapper">
                    <div class="content-header row" />
                    <div class="content-body">
                      <div class="body-content-overlay" />
                      <div class="todo-app-list">
                        <!-- Todo List starts -->
                        <div class="todo-task-list-wrapper list-group">
                          <ul
                            id="todo-task-list"
                            class="todo-task-list media-list"
                          >
                            <li
                              v-for="task in customTasks"
                              :key="task.id"
                              class="todo-item"
                              :class="{'completed': task.done}"
                            >
                              <div class="todo-title-wrapper">
                                <div class="todo-title-area">
                                  <i
                                    data-feather="more-vertical"
                                    class="drag-icon"
                                  />
                                  <div class="title-wrapper">
                                    <div class="form-check">
                                      <input
                                        id="customCheck1"
                                        type="checkbox"
                                        class="form-check-input"
                                        :checked="task.done"
                                        @click="markTask(task, $event)"
                                      >
                                      <label
                                        class="form-check-label"
                                        for="customCheck1"
                                      />
                                    </div>
                                    <span>{{ task.title }}</span>
                                  </div>
                                </div>
                                <div class="todo-item-action">
                                  <div class="badge-wrapper me-1" />
                                  <small class="text-nowrap text-muted me-1">{{ task.date }}</small>
                                </div>
                              </div>
                              <p><small v-html="task.message" /></p>
                            </li>
                          </ul>
                        </div>
                        <!-- Todo List ends -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      filter: 0,
    }
  },
  computed: {
    ...mapGetters({
      loggedUser: 'auth/admin',
      alerts: 'alerts/alerts',
      loading: 'alerts/loading',
      filters: 'filters/filters',
    }),
    tasks() {
      return this.alerts.filter(e => e.type.id === 1)
    },
    customTasks() {
      return this.alerts.filter(e => {
        if (this.filter === 1) {
          return (e.done === false || e.done === null) && e.type.id === 1
        }

        if (this.filter === 2) {
          return e.done === true && e.type.id === 1
        }

        return e.type.id === 1
      })
    },
  },
  methods: {
    async markTask(task, event) {
      await this.$store.dispatch('alerts/mark', task.id)
      task.done = event.target.checked
    },
  },
}
</script>

<style>

</style>
