<template>
  <div
    v-if="items && items.length > 0"
    class="col-12"
  >
    <div class="card card--profile-tasks">
      <div
        v-b-toggle="`collapsePendingNotifications`"
        class="card-header"
      >
        <h3 class="float-start"> Notifications pending of authorization <span
          v-if="!loading"
          class="badge bg-light-info"
        >{{ items.length }}</span>
        </h3>
        <div class="heading-elements heading-elements-custom">
          <div
            v-if="loggedUser.roles.includes('super-admin')"
            class="btn-group dropdown-icon-wrapper d-none d-sm-block disp-in"
          >
            <b-dropdown
              class="btn btn-secondary btn-sm"
              toggle-class="p-0"
            >
              <template #button-content>
                Multiple actions
              </template>
              <h6 class="dropdown-header">
                Change status
              </h6>
              <b-dropdown-item @click.stop="authorize(true)">
                <i
                  class="me-50"
                  data-feather="chevrons-right"
                /> Authorize
              </b-dropdown-item>
              <b-dropdown-item @click.stop="authorize(false)">
                <i
                  class="me-50"
                  data-feather="chevrons-right"
                /> Unauthorize
              </b-dropdown-item>
              <div class="dropdown-divider" />
              <b-dropdown-item @click="archive">
                <i
                  class="me-50"
                  data-feather="archive"
                /> Archive
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <ul class="list-inline mb-0 disp-in ms-2">
            <li>
              <a data-action="collapse"><svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-chevron-down"
              ><polyline points="6 9 12 15 18 9" /></svg></a>
            </li>
          </ul>
        </div>
      </div>
      <b-collapse
        :id="`collapsePendingNotifications`"
        :visible="fields.find(e => e.name == 'Notifications pending of authorization').visibility"
      >
        <div class="card-body">
          <div
            v-if="loading"
            class="loading position-relative"
          >
            <div class="effect-1 effects" />
            <div class="effect-2 effects" />
            <div class="effect-3 effects" />
          </div>
          <div
            v-else
            class="table-responsive"
          >
            <table class="table table-striped table-hover">
              <thead>
                <tr>
                  <th
                    v-if="loggedUser.roles.includes('super-admin')"
                    class="cursor-pointer"
                  >
                    <input
                      v-model="clicked"
                      type="checkbox"
                      @click="selectAll($event)"
                    >
                  </th>
                  <th v-if="loggedUser.roles.includes('super-admin')">
                    Researcher
                  </th>
                  <th>Activity</th>
                  <th>Date entry</th>
                  <th>Status</th>
                  <th class="text-end">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in items"
                  :key="index"
                >
                  <td v-if="loggedUser.roles.includes('super-admin')">
                    <input
                      :checked="clicked"
                      type="checkbox"
                      @click="select($event.target.checked, item)"
                    >
                  </td>
                  <td v-if="loggedUser.roles.includes('super-admin')">
                    {{ item.user.full_name }}
                  </td>
                  <td>{{ item.targetable_name }}</td>
                  <td>{{ item.begin_date }}</td>
                  <td v-html="item.targetable_status ? item.targetable_status : '-'" />
                  <td class="text-end">
                    <a
                      @click="$emit('showAlert', item)"
                    >
                      <i data-feather="eye" />
                    </a>
                    <a
                      v-if="item.targetable_url"
                      class="ms-1"
                      :href="$router.resolve({name: item.targetable_url, params: {id: item.targetable.id} , query: { actAs: $route.query.actAs } }).href"
                      target="_target"
                    >
                      <i data-feather="external-link" />
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import { BDropdown, BDropdownItem, BCollapse } from 'bootstrap-vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    BCollapse,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      userId: this.$route.params.id,
      loading: false,
      selectedItems: [],
      clicked: false,
    }
  },
  computed: {
    ...mapGetters({
      items: 'alerts/pendingAuth',
      fields: 'modals/tableSettingsFields',
      loggedUser: 'auth/admin',
    }),
  },
  async mounted() {
    this.$store.dispatch('alerts/fetchPendingAuth')
  },
  methods: {
    async archive(event) {
      event.stopPropagation()
      this.selectedItems.forEach(async e => {
        this.$store.dispatch('alerts/archive', e.id)
      })
      this.loading = true
      await this.$store.dispatch('alerts/fetchPendingAuth')
      this.loading = false
    },
    selectAll(event) {
      if (event.target.checked) {
        this.selectedItems = this.items
      } else {
        this.selectedItems = []
      }
    },
    async authorize(authorize) {
      this.loading = true
      this.selectedItems.forEach(async e => {
        await this.$store.dispatch('alerts/authorize', {
          auth: authorize,
          id: e.id,
        })
      })

      setTimeout(async () => {
        await this.$store.dispatch('alerts/fetchPendingAuth')
        this.loading = false
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 300)
    },
    select(click, item) {
      if (click) {
        this.selectedItems.push(item)
      } else {
        this.selectedItems.splice(this.selectedItems.findIndex(x => x.id === item.id), 1)
      }
    },
  },
}
</script>
